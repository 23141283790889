const baseUrl = "https://feedbackwapi.azurewebsites.net/api/";
const subKey = "subscription-key=8a66bfff6562481a8edfc881925adab4";

export const GetQuestions = async () => {
    return fetch(baseUrl + 'Questions', {
        method: 'get',
        headers: new Headers({
            'Content-Type': 'application/json'
        })
    })
        .then(response => {
            return response.json()
        });
};

export const GetEmployees = async () => {
    return fetch(`${baseUrl}Employee`, {
        method: 'get',
        headers: new Headers({
            'Content-Type': 'application/json'
        })
    })
        .then(response => {
            return response.json()
        });
};

export const GetEmployeeFeedback = async (id,q,year) => {
    return fetch(`${baseUrl}Feedback/GetEmployeeFeedback?id=${id}&quarter=${q}&year=${year}`, {
        method: 'get',
        headers: new Headers({
            'Content-Type': 'application/json'
        })
    })
        .then(response => {
            return response.json()
        });
};

export const SubmitEmployeeFeedback = async (empFeedback) => {
    debugger;
    return fetch(`${baseUrl}Feedback/UpdateEmployeeFeedback`, {
          method: 'post',
          headers: new Headers({
            'Content-Type': 'application/json'
          }),
          body : JSON.stringify(empFeedback)
        })
        .then(response => {
            return response.json();
        });
  };

  export const SubmitFeedback = async (feedbacks) => {
    return fetch(`${baseUrl}Feedback/SaveFeedback`, {
          method: 'post',
          headers: new Headers({
            'Content-Type': 'application/json'
          }),
          body : JSON.stringify(feedbacks)
        })
        .then(response => {
            return response.json();
        });
  };
  
  export const IsFeedbackSubmitted = async (empId) => {
    return fetch(`${baseUrl}Feedback/IsFeedbackSubmitted?empId=${empId}`, {
          method: 'get',
          headers: new Headers({
            'Content-Type': 'application/json'
          })
        })
        .then(response => {
            return response.json();
        });
  };
  
  export const GetAIRatings = async (inputText) => {
    return fetch(`${baseUrl}Feedback/GetAIRating?inputText=${inputText}`, {
          method: 'get',
          headers: new Headers({
            'Content-Type': 'application/json'
          })
        })
        .then(response => {
            return response.json();
        });
  };