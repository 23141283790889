import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Rating from '@material-ui/lab/Rating';
import Box from '@material-ui/core/Box';

const labels = {
  1: 'Poor',
  2: 'Needs improvement',
  3: 'Satisfactory',
  4: 'Very Good',
  5: 'Excellent',
};

const useStyles = makeStyles({
  root: {
    width: '100%',
    display: 'inline-flex',
    alignItems: 'bottom',
  },
});

export default function HoverRating({ id, onRatingChange }) {
  
    const [value, setValue] = useState(1);
    const [hover, setHover] = useState(-1);
    const classes = useStyles();
  
    const handleChange = (event, newValue) => {
      setValue(newValue);
      onRatingChange(id, newValue); // Notify the parent component of the rating change
    };

  return (
    <div className={classes.root}>
        <Rating name={id} defaultValue={2} max={5} 
        value={value}
        onChange={handleChange}
        onChangeActive={(event, newHover) => {
          setHover(newHover);
        }}/>
        {value !== null && <Box className='form-font' ml={2}>{labels[hover !== -1 ? hover : value]}</Box>}
    </div>
  );
}
