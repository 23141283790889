import React from 'react';
import PropTypes from 'prop-types';
import Rating from '@material-ui/lab/Rating';
import SentimentVeryDissatisfiedIcon from '@material-ui/icons/SentimentVeryDissatisfied';
import SentimentDissatisfiedIcon from '@material-ui/icons/SentimentDissatisfied';
import SentimentSatisfiedIcon from '@material-ui/icons/SentimentSatisfied';
import SentimentSatisfiedAltIcon from '@material-ui/icons/SentimentSatisfiedAltOutlined';
import SentimentVerySatisfiedIcon from '@material-ui/icons/SentimentVerySatisfied';
import EmojiEmotionsIcon from '@material-ui/icons/EmojiEmotions';
import MoodBadIcon from '@material-ui/icons/MoodBad';


 
const customIcons = {
 
  1: {
    icon: <SentimentVeryDissatisfiedIcon />,
    label: 'Very Dissatisfied',
  },
 
  2: {
    icon: <SentimentSatisfiedIcon />,
    label: 'Neutral',
  },
  3: {
    icon: <SentimentSatisfiedAltIcon />,
    label: 'Satisfied',
  },
  4: {
    icon: <SentimentVerySatisfiedIcon />,
    label: 'Very Satisfied',
  }, 
  5: {
    icon: <EmojiEmotionsIcon />,
    label: 'Happy',
  },
};

function IconContainer(props) {
  const { value, ...other } = props;
  return <span {...other}>{customIcons[value].icon}</span>;
}

IconContainer.propTypes = {
  value: PropTypes.number.isRequired,
};

export default function CustomizedRatings({OnRatingChange}) {
  const handleChange = (event, newValue) => {
    OnRatingChange(newValue);
  };
  return (
    <div>
       <span class="rating-title-span-radiogroup">Overall Satisfaction : </span> 
        <Rating
          className='smilygroup'
          name="customized-icons"
          defaultValue={1} size="large"
          max={5}
          getLabelText={(value) => customIcons[value].label}
          IconContainerComponent={IconContainer} onChange={handleChange}
        />
        
    </div>
  );
}